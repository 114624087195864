<template>
  <b-modal
    id="country-edit-modal"
    ref="country-edit-modal"
    title="Edit"
    centered
    hide-footer
    no-close-on-backdrop
    @shown="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.prevent>
        <b-row>
          <!-- !! Code -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="code"
              rules="required"
            >
              <HeroInputText
                id="code"
                v-model="country.code"
                label="Code"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <HeroInputText
                id="name"
                v-model="country.name"
                label="Name"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Local Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="local name"
              rules="required"
            >
              <HeroInputText
                id="local-name"
                v-model="country.local_name"
                label="Local Name"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Currency -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="currency"
              rules="required"
            >
              <HeroInputText
                id="currency"
                v-model="country.currency"
                label="Currency"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! SF Code -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="SF code"
              rules=""
            >
              <HeroInputText
                id="sf-code"
                v-model="country.sf_code"
                label="SF Code"
                column="4"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputText,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    editId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: true,

      // Validation
      required,

      routeId: '',
      country: {
        code: '',
        name: '',
        local_name: '',
        currency: '',
        sf_code: '',
      },
    }
  },
  methods: {
    async doCloseModal() {
      await this.$refs['country-edit-modal'].hide()
    },

    async doLoadData() {
      this.showOverlay = true

      this.routeId = this.$route.params.id

      try {
        const response = await axiosInstance.get(`countries/${this.editId}`)
        const country = response.data.data

        this.country.code = country.code || ''
        this.country.name = country.name || ''
        this.country.local_name = country.local_name || ''
        this.country.currency = country.currency || ''
        this.country.sf_code = country.sf_code || ''
      } catch (error) {
        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }

        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async validationForm() {
      let alertResult

      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        this.showOverlay = true

        const body = this.country

        try {
          const response = await axiosInstance.patch(`countries/${this.editId}`, body)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.$emit('success')
            this.$refs['country-edit-modal'].hide()
          }
        } catch (error) {
          this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        } finally {
          this.showOverlay = false
        }
      }
    },
  },
}
</script>
